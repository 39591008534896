<!--
 * @Descripttion: 日志内容列表
 * @version: 1.0.0
 * @Author: liujx@imyfone.cn
 * @Date: 2022-01-17 09:55:45
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2025-02-10 10:40:49
-->
<template>
  <template v-if="journalData.length > 0">
    <el-scrollbar ref="scrollbarRef" @scroll="scrollList" v-bind="$attrs">
      <div v-for="(item, index) in journalData" :key="item.id" class="journal-item border-6" :data-itemId="item.id"
      :ref="el=>{journalRefList[index] = el}">
        <UserAvatar
          v-if="!onlyPreview"
          :user="{ name: item.name || item.user_name, avatar: item.avatar, id: item.user_id }"
          size="38"
          fontSize="14"
          medalSize="55"
        ></UserAvatar>
        <div class="journal-content">
          <div :class="['journal-top', onlyPreview ? 'journal-top-flex' : '']">
            <p class="data" v-if="onlyPreview && item.created_at">
              <span>
                {{getDate(item.created_at.substr(0, 10))}}
                {{getDate(item.created_at.substr(0, 10)).length === 2 ? item.created_at.substr(11, 5) : '' }}
              </span>
              <span v-if="templateName === item.template_name" class="improt" @click="importContent(index)">导入到编辑区</span>
            </p>
            <div>
              <p class="title oneline-overflow" >
                <template v-if="item.template_name !=='空白模板'">
                  {{`${item.name ? item.name:item.user_name}的${item.template_name}`}}
                </template>
                <template v-else>
                  {{item.fields[0].field_name}}
                </template>
              </p>
              <p class="time" v-if="!onlyPreview">{{getDate(item.created_at.substr(0, 10))}} {{item.created_at.substr(11, 5)}}</p>
            </div>
            <div v-if="!onlyPreview && item.user_id === currentUser.id" class="dropdown">
              <div class="dropdown-link" @click.stop="dropdownShowChange(item)">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="3" cy="10" r="2" fill="#999"/><circle cx="10" cy="10" r="2" fill="#999"/><circle cx="17" cy="10" r="2" fill="#999"/></svg>
              </div>
              <ul v-show="item.dropdownShow" class="dropdown-menu" :ref="el=> {dropdownShowRefList[index] = el}">
                <li @click.stop="edit(item)" class="dropdown-item">修改日志</li>
                <!-- <li @click.stop="remove(item.id,index)" class="dropdown-item">删除</li> -->
              </ul>
            </div>
          </div>
          <div class="journal-bottom">
            <div v-for="data in item.fields" :key="data.id" class="journal">
              <p class="title" v-if="item.template_name !== '空白模板'"> {{`${data.field_name}：`}}</p>
              <template v-if="data.content">
                <p class="content scroll-bar" v-html="data.content">
                </p>
              </template>
            </div>
            <template v-if="item.images">
              <div class="journal">
                <p class="title">图片：</p>
                <div :class="['journal-file-list', onlyPreview ? 'journal-file-list-prev' : '']">
                  <ImagePrev :imageList="item.images.split('^')"></ImagePrev>
                </div>
              </div>
            </template>
            <template v-if="item.files">
              <div class="journal">
                <p class="title">附件：</p>
                <div class="journal-file-list">
                  <FileList :fileList="item.files" ></FileList>
                </div>
              </div>
            </template>

          </div>
          <template v-if="!onlyPreview">
            <el-button  type="text" @click="setJournalObj(item)"> {{item.read_count}}人已读</el-button>
            <p class="like-info" v-if="item.like_count > 0">
              <span @click="queryLikeUser(item)">{{item.like_user}} </span>
              <span @click="queryLikeUser(item)" v-if="item.like_count > 5">等</span>
              <span @click="queryLikeUser(item)">{{item.like_count}}人赞了本篇日志</span>
            </p>
            <!-- 当前高亮日志才显示评论框和评论列表-->
            <template v-if="[activeIndex-1,activeIndex,activeIndex +1].includes(index)">
              <div class="comment-box" :id="`comment_${item.id}`">
                <EditorInput placeholder="输入你的评论~"
                  :ref="el=> {editorInputRefList[index] = el}"
                  :limit="limit"
                  v-model:inputContent="inputContent"
                  @focus="item.isInputFocus = $event"
                  :id="Number(key + item.id)"
                ></EditorInput>

                <template v-if="!item.isInputFocus">
                  <LikeIconAnimation
                    @handleClick="updateLike"
                    :data="item"
                    :isTextShow="false"
                    type="4"
                  >
                  </LikeIconAnimation>
                </template>
                <template v-else >
                  <EditorButtons
                    :isImgBtnVisible="false"
                    @updateContent="updateContent"
                    :id="Number(key + item.id)"
                    ></EditorButtons>
                  <svg :class="inputContent.text.length > 0 ? 'active' : ''" @click="addComment(item)" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><circle opacity=".8" cx="15" cy="15" r="15" fill="#7972F0"/><path d="M10.613 16.535L5 13.3 22 7l-2.977 14.493-7.242-4.39 6.473-6.615-7.64 6.047zm1.218 1.747V22l2.4-2.306-2.4-1.412z" fill="#fff"/></svg>
                </template>
              </div>
              <div class="comment">
                <CommentList
                  :ref="el => commentListRef[index] = el"
                  :id="item.id"
                  :userId="item.user_id"
                  commentType="4"
                >
                  <template #buttonBox>
                    <template v-if="commentListRef[index]?.commentCount > 3">
                        <template v-if="commentListRef[index].commentCount !== commentListRef[index].commentList.length">
                          <el-button class="color" type="text" size="small"  @click="commentListRef[index].refreshCommentList(9999)">
                            共{{commentListRef[index].commentCount}}条回复
                            <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M11 1L5.99963 6.00037L0.999255 1" stroke="#7972F0" stroke-width="2"/>
                            </svg>
                          </el-button>
                        </template>
                        <template v-else>
                          <el-button class="color" type="text" size="small"  @click="hiddenMore(index, item.id)">收起</el-button>
                        </template>
                    </template>
                  </template>
                </CommentList>
              </div>
            </template>
          </template>
        </div>
      </div>
      <slot name="slot"></slot>
    </el-scrollbar>
    <el-dialog v-model="dialogVisible" :show-close="false" custom-class="user-dialog scroll-bar" width="596px">
      <template #title>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <template v-for="{label, name, data, text} in tabList" :key="name">
            <el-tab-pane :label="label" :name="name">
              <template v-if="data.length > 0">
                <div v-for="{id, user} in data" :key="id" class="user-avatar-box" v-show-tip>
                  <UserAvatar :user="user" size="40" fontSize="14" medalSize="60"></UserAvatar>
                  <el-tooltip :content="user.name" placement="bottom" effect="light">
                    <p>{{user.name}}</p>
                  </el-tooltip>
                </div>
              </template>
              <template v-else>
                <p class="no-people">{{ text }}</p>
              </template>
            </el-tab-pane>
          </template>
        </el-tabs>
      </template>
    </el-dialog>
  </template>
  <div v-else class="empty-box border-6">
    <empty-data
      image="no-journal-list"
      description="无历史日志"
      size="170"></empty-data>
    <el-button type="primary" v-if="journalBtnVisible"
    @click="gotoJournal">开始你的第一篇日志</el-button>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, computed, onBeforeUpdate, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { getReadUserList, deleteJournal, getLikeUserList, setJournalRead } from '@/apis/journal.js'
import { dialogMsg, confirm } from '@/utils/dialogMsg.js'
import { feedsFeedComment } from '@/apis/blogs.js'
import { getAtUserID, getDate } from '@/utils/tools'
import eventBus from '@/utils/eventBus.js'
import mySocket from '@/mixin/socket.js'
import EditorInput from '@/components/commonEditor/EditorInput.vue'
import EditorButtons from '@/components/commonEditor/EditorButtons.vue'
import CommentList from '@/components/comment/CommentList.vue'
import LikeIconAnimation from '@/components/icon/LikeIconAnimation.vue'

export default {
  props: {
    onlyPreview: {
      type: Boolean, // 是否仅预览，不可评论删除等操作
      default: false
    },
    journalList: {
      type: Array, // 日志数组
      default: () => []
    },
    journalBtnVisible: {
      type: Boolean, // 写日志按钮是否可见
      default: false
    },
    templateName: {
      type: String, // 模板名称
      default: ''
    },
    activeType: {
      type: String,
      default: ''
    },
    depFlag: {
      type: String
    },
    activeIndex: {
      type: Number,
      default: 0
    }
  },
  emits: ['delete', 'scoller', 'import'],
  components: { EditorInput, EditorButtons, CommentList, LikeIconAnimation },
  setup (props, { emit }) {
    onMounted(() => {
      // 左侧点击，滚动右侧
      eventBus.$on('scollerRightJournal', (index) => {
        console.log('左侧点击，触发右侧滚动监听', index)
        let eleTop = 0
        let eleHeight = 0
        if (state.journalRefList[index] && state.scrollbarRef) {
          eleTop = state.journalRefList[index].offsetTop
          eleHeight = state.journalRefList[index].offsetHeight
          if (eleTop + eleHeight < state.scrollbarRef.scrollbar.scrollHeight) { // 没有滚动条，直接已读，查询评论
            activeJournal(index)
          }
          state.scrollbarRef.setScrollTop(eleTop > 0 ? eleTop - 1 : eleTop)
        }
      })
      // 阅读成功，已读数量+1
      const addReadCount = (id) => {
        state.journalData.forEach(i => i.id === id && (i.read_count += 1))
      }
      eventBus.$on('addReadCount', addReadCount)
      // 监听鼠标点击事件
      document.addEventListener('click', (e) => {
        state.journalData.forEach(item => {
          item.dropdownShow = false
        })
      })
    })
    onBeforeUpdate(() => {
      state.journalRefList = []
      state.editorInputRefList = []
      state.dropdownShowRefList = []
    })
    const key = new Date().getTime()
    const router = useRouter()
    const store = useStore()

    const state = reactive({
      journalData: computed(() => { return getJournalData(props.journalList) }),
      activeIndex: computed(() => props.activeIndex),
      dialogVisible: false,
      activeName: 'read',
      journalObj: {
        readList: [],
        unReadList: [],
        likeList: []
      }, // 当前选择的日志
      tabList: [
        { label: '已读', name: 'read', text: '还没有人已读', data: computed(() => { return state.journalObj.readList }) },
        { label: '未读', name: 'unRead', text: '所有人都已读', data: computed(() => { return state.journalObj.unReadList.filter(i => i.user) }) },
        { label: '点赞', name: 'like', text: '还没有人点赞', data: computed(() => { return state.journalObj.likeList }) }
      ],
      ossUrl: process.env.VUE_OSS_DOMIAN ? process.env.VUE_OSS_DOMIAN : 'https://static-test-sns.iappdata.com',
      editorInputRefList: [],
      limit: 100,
      inputContent: { // 评论内容
        text: '',
        html: ''
      },
      commentListRef: [],
      scrollbarRef: null,
      journalRefList: [],
      currentUser: computed(() => store.state.userInfo.userInfos),
      scrollIndexs: [0], // 记录已滚动的index列表
      dropdownShowRefList: [],
      templateName: computed(() => props.templateName),
      depFlag: computed(() => props.depFlag)
    })

    watch(() => state.depFlag, (value) => {
      value && (state.scrollIndexs = [])
    })

    const getJournalData = (data) => {
      console.log('readData', data)
      state.journalRefList = []
      props.journalList.forEach((item, index) => {
        item.index = index //  序号
        item.readList = [] // 已读用户
        item.unReadList = [] // 未读用户
        item.likeList = [] // 点赞用户
        item.dropdownShow = false
        item.like_count = item.like_info.total
        item.like_user = item.like_info.user_list.map((data) => {
          return data.name ? data.name : data.user.name
        }).join(',')
      })
      if (props.journalList.length > 0) {
        readJournal(props.journalList[0])
      }
      return props.journalList
    }

    // 展示已读/未读用户列表
    const setJournalObj = (item) => {
      state.journalObj = item
      state.activeName = 'read'
      state.dialogVisible = true
      queryReadUser(item)
    }
    // 查询
    const queryReadUser = (item) => {
      const data = { type: 4, id: item.id }
      getReadUserList(data).then(res => {
        if (res.code === 0) {
          state.journalObj.readList = res.data.read_list.filter(user => user.user_id !== item.user_id)
          state.journalObj.unReadList = res.data.unread_list.filter(user => user.user_id !== item.user_id)
        }
      })
    }
    // 删除日志
    const remove = (id, index) => {
      confirm({
        message: '确认删除当前日志吗？删除后请重新提交',
        title: '删除',
        success: function () {
          deleteJournal({ id }).then(res => {
            if (res.code === 0) {
              emit('delete', id)
              dialogMsg('success', '删除成功')
              store.commit('setMessageDel', true)
            } else {
              dialogMsg('error', res.msg)
            }
          })
        },
        cancel: function () {
        }
      })
    }

    // 展示点赞用户弹窗
    const queryLikeUser = (item, type = 'click') => {
      state.journalObj = item
      type === 'click' && (state.activeName = 'like') && (state.dialogVisible = true)
      getLikeUserList({ id: item.id }).then(res => {
        if (res.code === 0) {
          state.journalObj.likeList = res.data.like_list
        }
      })
    }

    // 写日志
    const gotoJournal = () => {
      router.push({ name: 'writeJournal' })
    }

    const { sendToSocket } = mySocket()
    // 发布评论
    const addComment = (item) => {
      const data = {
        id: item.id,
        commentable_type: 4,
        body: state.inputContent.html,
        target_user: item.user_id,
        at_user: getAtUserID(state.inputContent.html)
      }
      feedsFeedComment(data).then(res => {
        if (res.code === 0) {
          dialogMsg('success', '评论成功')
          state.inputContent = { text: '', html: '' }
          const socketArr = [item.user_id]
          sendToSocket(socketArr, { type: 5 })
          sendToSocket(data.at_user, { type: 4 })
          state.editorInputRefList[item.index || 0].clearContent()
          state.commentListRef = state.commentListRef.filter(ref => !!ref) // 清空不存在的ref
          state.commentListRef.filter(ref => ref.commentId === item.id)[0].refreshCommentList(3)
        } else {
          dialogMsg('error', res.msg)
        }
      })
    }

    // 点赞/取消点赞
    const updateLike = (item) => {
      if (item.is_like === 1) { // 点赞
        // 少于5，添加点赞人的名字
        if (item.like_count < 5) {
          const str = item.like_count > 0 ? `,${state.currentUser.name}` : state.currentUser.name
          !item.like_user.includes(str) && (item.like_user += str)
        }
        // 修改状态和数量
        item.is_like = 2
        item.like_count++
      } else if (item.is_like === 2) { // 取消点赞
        // 少于5，删除点赞人的名字
        if (item.like_count <= 5) {
          const str = item.like_count > 1 ? `,${state.currentUser.name}` : state.currentUser.name
          // console.log(item.like_user)
          item.like_user = item.like_user.replace(str, '')
        }
        // 修改状态和数量
        item.is_like = 1
        item.like_count > 0 && item.like_count--
      }
      setTimeout(() => {
        // 更新点赞用户列表
        queryLikeUser(item, 'auto')
      }, 1000)
    }

    const edit = (data) => {
      store.commit('work/setJournalId', data.id)
      // 跳转页面
      router.push({ name: 'updateJournal' })
    }

    const handleClick = (obj) => {
      state.activeName = obj.props.name
      if (state.activeName === 'like') {
        queryLikeUser(state.journalObj)
      } else if (state.activeName === 'read') {
        queryReadUser(state.journalObj)
      }
    }

    const scrollList = ({ scrollTop }) => {
      const lastObj = state.journalRefList[state.journalData.length - 1] // 最后一个
      const allHeight = scrollTop + state.scrollbarRef.scrollbar.scrollHeight
      const isLast = allHeight > lastObj.offsetTop + lastObj.offsetHeight
      let index = 0
      if (scrollTop === 0) { // 第一个
        index = 0
      } else if (isLast) { // 最后一个
        index = state.journalData.length - 1
      } else { // 其他
        index = state.journalRefList.findIndex(item => (item.offsetTop + item.offsetHeight) > scrollTop + 1)
      }
      setTimeout(() => {
        emit('scoller', index)
      }, 200)

      // 读日志
      if (!state.scrollIndexs.includes(index)) {
        state.scrollIndexs.push(index)
        activeJournal(index)
      }
    }

    // 下拉框显示与隐藏
    const dropdownShowChange = (item) => {
      item.dropdownShow = !item.dropdownShow
    }
    // 已读
    const activeJournal = (index) => {
      // 有左侧导航
      // 没有左侧导航
      props.activeType === '2' ? readJournal(state.journalData[index]) : eventBus.$emit('readJournal', state.journalData[index])
    }

    // 导入日志到编辑区
    const importContent = (index) => {
      confirm({
        message: '是否导入所选内容？导入会覆盖当前已编辑的草稿内容',
        title: '导入日志',
        success: () => {
          const data = JSON.parse(JSON.stringify(state.journalData[index]))
          data.user_name = data.name
          emit('import', data)
        },
        cancel: () => {
        }
      })
    }

    // 阅读日志
    const readJournal = (journal) => {
      state.journal = journal
      // 其他的
      if (journal.id && !journal.read_time) {
        const obj = {
          id: journal.id,
          type: 4
        }
        setJournalRead(obj).then(res => {
          if (res.code === 0) {
            journal.read_time = res.data.read_time
            if (state.journal.user_id !== state.currentUser.id) { // 不是自己阅读自己的日志
              // 右侧已读人数+1
              state.journalData.forEach(i => i.id === obj.id && (i.read_count += 1))
              // vuex未读日志-1
              store.commit('work/setReceiveNewJournal', -1)
            }
          }
        })
      }
    }
    eventBus.$on('rightReadLog', readJournal)
    // 插入表情更新html
    const updateContent = (html, id) => {
      state.editorInputRefList = state.editorInputRefList.filter(ref => !!ref)
      state.editorInputRefList.find(i => i.id === id).getVal(undefined, id)
    }

    const hiddenMore = (index) => {
      // 刷新评论列表
      state.commentListRef[index].refreshCommentList(3)
      // 滚动处理
      const eleTop = state.editorInputRefList[index].$el.offsetTop - 200
      state.scrollbarRef.setScrollTop(eleTop)
    }

    // const observeElementVisibility = (el, callback) => {
    //   const observer = new IntersectionObserver(
    //     (entries) => {
    //       entries.forEach(entry => {
    //         callback(entry.isIntersecting) // true 表示元素可见
    //       })
    //     },
    //     { threshold: [0] } // threshold 为 0 表示只要有一点进入视口就算可见
    //   )
    //   observer.observe(el)
    // }
    return {
      ...toRefs(state),
      setJournalObj,
      remove,
      queryLikeUser,
      gotoJournal,
      addComment,
      updateLike,
      edit,
      handleClick,
      scrollList,
      dropdownShowChange,
      getDate,
      key,
      importContent,
      updateContent,
      hiddenMore
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../../assets/less/writeJourn';
.empty-box{
  background: @colorWhite;
  height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.journal-item{
  display: flex;
  margin-bottom: 8px;
  background: @colorWhite;
  padding: 35px 50px 31px 22px;
  .journal-content{
    width: 100%;
    overflow: hidden;
    .el-button{
      padding-bottom: 14px;
    }
    :deep(.user-avatar) {
      display: inline-block;
    }
  }
}

:deep(.color.el-button--text) {
  color: @active-text-color;
}

.journal-top{
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  &.journal-top-flex{
    flex-direction: column;
  }
  .data{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #F1F1F1;
    margin-bottom: 8px;
    padding-bottom: 8px;
    color: @second-text-color;
    font-size: 12px;
  }
  .improt {
    cursor: pointer;
    &:hover {
      color: @active-text-color;
    }
  }
  .title{
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 2px;
    line-height: 24px;
    color: @default-text-color;
  }
  .time{
    color: @second-text-color;
    font-size: 12px;
  }
  .dropdown{
    position: relative;
  }
  .dropdown-link{
    cursor: pointer;
    display: inline-block;
  }
  .dropdown-menu{
    position: absolute;
    right: 0;
    width: 110px;
    background: #FFFFFF;
    border: 1px solid #F1F1F1;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 5px 0;
    .dropdown-item{
      font-size: 13px;
      height: 32px;
      line-height: 32px;
      padding-left: 23px;
      color: @default-text-color;
      cursor: pointer;
      list-style: none;
    }
    .dropdown-item:hover{
      background: #F1F4F4;
    }
  }
}
.journal-bottom .journal{
  line-height: 25px;
  .title{
    font-size: 16px;
    font-weight: bold;
    padding: 8px 0;
    line-height: 21px !important;
    color: @default-text-color;
  }
  .content{
    white-space: pre-line;
    font-size: 14px;
    margin-bottom: 24px;
    word-break: break-word;
    overflow: auto;
  }
}

.el-tab-pane{
  display: flex;
  flex-wrap: wrap;
}

.journal-file-list{
  display: flex;
  flex-wrap: wrap;
  :deep(.el-image){
    width: 74px;
    height: 74px;
    border-radius: 6px;
    margin: 0 8px 8px 0;
  }
  &.journal-file-list-prev :deep(.el-image){
      width: 38px;
      height: 38px;
      border: 1px solid #f1f1f1;
      margin: 0 4px 4px 0;
  }
}
.el-button--text{
  color: @second-text-color;
}
.like-info{
  color: @second-text-color;
  font-size: 13px;
  cursor: pointer;
  border-top: 1px solid #F1F1F1;
  padding: 15px 0;
  span:nth-child(1) {
    color: @default-text-color;
    margin-right: 10px;
  }
}
.comment-box{
  display: flex;
  align-items: center;
  .arena-container{
    margin-right: 8px;
    width: 100%;
  }
  svg{
    cursor: pointer;
    &.active{
      circle {
        opacity: 1;
      }
    }
  }
  :deep(.wrapper) {
    .emoji-picker {
      left: unset;
      right: 54px;
      top: -210px;
    }
    .emoji-button{
      margin: 0 8px 0 0;
    }
  }
}
.journal-item {
  .user-avatar{
    align-items: flex-start !important;
  }
}
:deep(.el-tabs__content) {
  padding: 5px;
}
.user-avatar-box{
  margin-right: 30px;
  width: 40px;
  margin-bottom: 18px;
  &:nth-child(8n){
    margin-right: 0;
  }
  .user-avatar{
    margin: 0;
  }
  p{
    text-align: center;
    color: @default-text-color;
    font-size: 12px;
    line-height: 16px;
    margin-top: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

</style>
<style lang="less">
  .user-dialog{
    :deep(.el-dialog__body){
      display: none;
    }
    height: 456px;
    overflow: auto;
    border-radius: 6px;
    .el-dialog__header{
      padding-top: 13px;
    }
    .el-tabs__item{
      font-family: Microsoft YaHei;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      color: @default-text-color;
      padding: 0 16px
    }
    .el-tabs__item:hover{
      color: @active-text-color;
    }
    .is-active{
      color: @active-text-color;
      font-weight: bold;
    }
    .el-tabs__active-bar{
      background-color: @active-text-color;
    }
    .no-people{
      height: 200px;
      transform: translate(243px,153px);
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: @second-text-color;
    }
  }
  .comment {
    margin: 10px 0 10px 10px;

    .comment-list {
      li:not(.children) {
        .user-avatar {
            min-width: 32px;
            width: 32px;
            height: 32px;
            line-height: 32px;
        }
      }
    }
  }
</style>
